<template>
  <base-dialog
    :show="isEmail"
    @close="closeAllEmail"
    :title="`${t.year} ${t.manufacturer} ${t.model}`"
    :width="true"
  >
    <form @submit.prevent="" class="second-form">
      <div @click="closeAllEmail" class="close-btn">&times;</div>
      <div class="d-sm-flex gap-3">
        <div class="left">
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !firstName && isEmpty }"
              for="firstName"
              >First Name</label
            >
            <input
              v-model="firstName"
              class="form-control"
              type="text"
              id="firstName"
              placeholder="Enter your first name"
            />
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !lastName && isEmpty }"
              for="lastName"
              >Last Name</label
            >
            <input
              v-model="lastName"
              class="form-control"
              type="text"
              id="lastName"
              placeholder="Enter your last name"
            />
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !email && isEmpty }"
              for="email"
              >Email</label
            >
            <input
              v-model="email"
              class="form-control"
              type="email"
              id="email"
              placeholder="Enter your email address"
            />
          </div>
          <div class="input-wrap">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !phone && isEmpty }"
              for="phone"
              >Phone</label
            >
            <div class="input-group d-flex">
              <base-dropdown
                :options="allCountry"
                @input="getCountry"
              ></base-dropdown>
              <input
                v-model="phone"
                id="phone"
                class="form-control"
                type="text"
              />
            </div>
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !postalCode && isEmpty }"
              for="postalCode"
              >Postal Code</label
            >
            <input
              v-model="postalCode"
              class="form-control"
              type="text"
              id="postalCode"
              placeholder="Enter your postal code"
            />
            <small>Postal code min 5 max 6 characters</small>
          </div>
        </div>
        <div class="right">
          <div class="input-wrap">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !message && isEmpty }"
              for="message"
              >Message</label
            >
            <textarea
              v-model="message"
              class="form-control"
              id="message"
              maxlength="500"
              placeholder="Enter your inquiry (Up to 500 characters)"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="d-flex gap-3 mt-3">
        <div class="wrap">
          <input v-model="isConfirm" type="checkbox" id="rule" />
          <label
            class="confirm-label"
            for="rule"
            :class="{ 'text-danger': !isConfirm && isEmpty }"
            >Click here to acknowledge you understand our Privacy Policy, which
            explains how the Sandhills Group Companies use and collect personal
            information when you register with us or place an order for any of
            our Brands. We will not share your information for marketing
            purposes with companies outside our group companies and you may
            withdraw your consent at any time.</label
          >
        </div>
        <div class="button-wrap d-flex flex-column align-items-center gap-2">
          <recaptcha-button></recaptcha-button>
          <button
            @click="sendEmail2"
            class="d-flex justify-content-center gap-2 align-items-center align-self-end"
          >
            Submit
            <base-loader v-if="loading"></base-loader>
          </button>
        </div>
      </div>
    </form>
  </base-dialog>
  <!--  -->
  <div v-if="t" class="each-compare">
    <div class="compare-title my-2">
      <h6 class="fw-bold">{{ t.year }} {{ t.manufacturer }} {{ t.model }}</h6>
    </div>
    <div class="compare-img">
      <div @click="closeEachCompare(t.id)" class="close-compare">&times;</div>
      <template v-if="t.media.length">
        <img :src="baseUrl + t.media[0].image" alt="" />
      </template>
      <img v-else src="../../assets/no-image.png" alt="" class="border" />
    </div>
    <div class="price">
      <div class="text-center my-1">
        <fa class="fa" :icon="['fas', 'images']" /> {{ t.media?.length }}
      </div>
      <div class="mb-1">
        <h5 class="fw-bold">USD ${{ addSpace(t.price) }}</h5>
        <p>Burr Ridge, Illinois 60527</p>
      </div>
    </div>
    <h6 class="fw-bold mb-1">General</h6>
    <div class="general">
      <div>
        <p>Year</p>
        <span>{{ t.year }}</span>
      </div>
      <div>
        <p>Manufacturer</p>
        <span>{{ t.manufacturer }}</span>
      </div>
      <div>
        <p>Model</p>
        <span>{{ t.model }}</span>
      </div>
      <div>
        <p>VIN</p>
        <span>{{ t.vin }}</span>
      </div>
      <div>
        <p>Condition</p>
        <span>{{ t.condition }}</span>
      </div>
      <div>
        <p>Stock Number</p>
        <span>{{ t.stock_number }}</span>
      </div>
      <div>
        <p>Mileage</p>
        <span>{{ addSpace(t.mileage) }} mi</span>
      </div>
      <div>
        <p>Odometer</p>
        <span>{{ t.odometer }}</span>
      </div>
      <div>
        <p>State DOT</p>
        <span>{{ t.state_dot }}</span>
      </div>
      <div>
        <p>Description</p>
        <span class="text-desc">{{ t.description }}</span>
      </div>
    </div>
    <template v-if="t.truck_engine">
      <h6 class="fw-bold mb-1">Engine</h6>
      <div class="general">
        <!-- {{ t.truck_powertrain }} -->
        <div v-for="(value, key, i) of t.truck_engine" :key="i">
          <p>{{ capitalizeProp(key) }}</p>
          <span
            >{{ value }}
            {{
              key == "horsepower"
                ? "HP"
                : key == "engine_displacement"
                ? "l"
                : ""
            }}
          </span>
        </div>
      </div>
    </template>
    <template v-if="t.truck_powertrain">
      <h6 class="fw-bold mb-1">Powertrain</h6>
      <div class="general">
        <div v-for="(value, key, i) of t.truck_powertrain" :key="i">
          <p>{{ capitalizeProp(key) }}</p>
          <span>{{ value }} {{ key == "number_of_speeds" ? "Spd" : "" }}</span>
        </div>
      </div>
    </template>
    <template v-if="t.truck_chassis">
      <h6 class="fw-bold mb-1">Chassis</h6>
      <div class="general">
        <div v-for="(value, key, i) of t.truck_chassis" :key="i">
          <p>{{ capitalizeProp(key) }}</p>
          <span>{{ value }} {{ key == "wheelbase" ? "in" : "" }}</span>
        </div>
      </div>
    </template>
    <template v-if="t.truck_interior">
      <h6 class="fw-bold mb-1">Interior</h6>
      <div class="general">
        <div v-for="(value, key, i) of t.truck_interior" :key="i">
          <p>{{ capitalizeProp(key) }}</p>
          <span>{{ value }}</span>
        </div>
      </div>
    </template>
    <template v-if="t.truck_exterior">
      <h6 class="fw-bold mb-1">Exterior</h6>
      <div class="general">
        <div v-for="(value, key, i) of t.truck_exterior" :key="i">
          <p>{{ capitalizeProp(key) }}</p>
          <span>{{ value }}</span>
        </div>
      </div>
    </template>
    <div class="d-grid gap-1">
      <button @click="isEmail = true">
        <fa class="fa" :icon="['fas', 'images']" /> Message Seller
      </button>
      <a href="tel:630-756-2614" class="text-light">
        <button class="w-100">
          <fa class="fa" :icon="['fas', 'phone']" />
          Call Seller
        </button>
      </a>
      <router-link :to="'/trucks/' + t.id">
        <button class="w-100">
          <fa class="fa text-light" :icon="['fas', 'magnifying-glass-plus']" />
          View Details
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import customAxios from "../../api";
import { defineAsyncComponent } from "vue";
const BaseDialog = defineAsyncComponent(() => import("../UI/BaseDialog.vue"));
const BaseLoader = defineAsyncComponent(() => import("../UI/BaseLoader.vue"));
import BaseDropdown from "../UI/BaseDropdown.vue";
import CountryList from "country-list-with-dial-code-and-flag";
import { addSpace, capitalizeProp, checkEmail } from "../../utils";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  props: ["t"],
  components: {
    BaseDialog,
    BaseDropdown,
    BaseLoader,
  },
  data() {
    return {
      isEmail: false,
      allCountry: CountryList,
      baseUrl: process.env.VUE_APP_IMAGE_URL,
      firstName: "",
      lastName: "",
      email: "",
      isConfirm: false,
      message: "",
      phone: "",
      phoneCode: "+1",
      postalCode: "",
      isEmpty: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.isLoading;
    },
    filteredPhone() {
      return this.phoneCode + this.phone;
    },
  },
  methods: {
    addSpace,
    capitalizeProp,
    getCountry(val) {
      this.phoneCode = val;
    },
    closeAllEmail() {
      this.isEmail = false;
      this.reset();
    },
    reset() {
      this.isConfirm = false;
      this.isEmpty = false;
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.phone = "";
      this.phoneCode = "+1";
      this.message = "";
      this.postalCode = "";
    },
    async sendEmail2() {
      if (!this.isConfirm) {
        this.isEmpty = true;
        return;
      }
      if (
        !this.firstName ||
        !this.lastName ||
        !this.email ||
        !this.message ||
        !this.phone ||
        !this.postalCode ||
        checkEmail(this.email)
      ) {
        this.isEmpty = true;
        return;
      }
      try {
        this.$store.dispatch("changeLoading", true);
        await customAxios.post("create-truck-email/", {
          truck: this.t.id,
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          phone: this.filteredPhone,
          postal_code: this.postalCode,
          message: this.message,
        });
        this.$store.dispatch("changeLoading", false);
        this.closeAllEmail();
        toast.success("Success!");
      } catch (e) {
        this.$store.dispatch("changeLoading", false);
        this.$store.dispatch("setError", e);
      }
    },
    closeEachCompare(id) {
      this.$store.commit("addCompareTrucks", { add: false, id: id });
    },
  },
};
</script>

<style scoped>
.input-group {
  align-items: end;
}
.close-compare {
  transform: scaleX(0);
  transform-origin: right;
  transition: all 0.15s ease-in-out;
  position: absolute;
  top: 0;
  right: -1px;
  padding: 0 12px;
  font-size: 22px;
  background: rgb(196, 193, 193);
  cursor: pointer;
}
.close-compare:hover {
  background: rgb(182, 180, 180);
}
.each-compare {
  position: relative;
  padding: 2px 8px 12px;
  border: 1px solid #ddd;
}
.each-compare:hover .close-compare {
  transform: scale(1);
}
.price h5 {
  font-size: 1.1rem;
  color: #0057b7;
}
.each-compare p {
  font-size: 0.9rem;
}
.each-compare span {
  font-size: 0.85rem;
}
.general {
  margin-bottom: 6px;
}
.general div {
  line-height: 17px;
  margin-left: 10px;
  margin-bottom: 2px;
}
.general p {
  font-weight: bold;
}
.text-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
}
button {
  font-size: 0.85rem;
  background: #0057b7;
  color: #fff;
  padding: 6px;
  border-radius: 0.2rem;
}
.compare-img {
  height: 200px;
  width: 100%;
  /* overflow: hidden; */
}
.compare-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
form {
  padding: 0;
  margin-top: 0.8rem;
}
form .left,
form .right,
.button-wrap,
.wrap {
  flex: 1;
}
.input-wrap {
  display: flex;
  flex-direction: column;
}
input,
textarea,
select {
  margin-top: 1px;
  padding: 4px;
  font-size: 0.9rem;
}
label {
  font-size: 0.9rem;
}
::placeholder {
  font-size: 0.8rem;
}
.right .input-wrap:last-child {
  height: 57%;
}
textarea {
  height: 100%;
}
.wrap {
  line-height: 14px;
}
.wrap input {
  margin-right: 4px;
}
.button-wrap button {
  width: 100%;
}
.wrap input,
.wrap label {
  display: inline;
  font-size: 0.68rem;
}
button.align-self-end {
  background: #0057b7;
  color: #fff;
  padding: 4px;
  border-radius: 0.2rem;
}
.recaptcha-wrapper label {
  font-size: 0.85rem;
}

.first-form .right label {
  height: 7%;
}
.first-form .right .input-wrap {
  height: 97%;
}
.first-form .right textarea {
  height: 100%;
}
</style>
