<template>
  <section>
    <div class="home-sub-title text-center">
      <p>5501 Adams ST Unit C Matteson, IL 60443</p>
    </div>
  </section>
  <section class="my-5">
    <div class="container">
      <div class="row justify-content-center mb-2">
        <div class="col-md-8 p-0">
          <router-link to="/trucks">
            <fa class="fa" :icon="['fas', 'angle-left']" /> Previous Page
          </router-link>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-8 p-0 mb-1">
          <div class="title d-flex gap-2">
            <div class="me-auto"><h4 class="fw-bold">COMPARE LISTINGS</h4></div>
            <button @click="printPdf">
              <fa class="fa text-light" :icon="['fas', 'print']" /> Print
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="my-5">
    <div class="container">
      <div class="row justify-content-center">
        <template v-if="filteredTrucks?.length">
          <div
            v-for="truck in filteredTrucks"
            :key="truck.id"
            class="col-md-3 my-2 my-md-0"
          >
            <each-compare :t="truck"></each-compare>
          </div>
        </template>
        <div class="col-8 text-center mx-auto p-3" v-else>
          <h5 class="text-secondary">Please, select trucks to compare</h5>
        </div>
      </div>
    </div>
  </section>
  <base-footer></base-footer>
</template>

<script>
import EachCompare from "../components/trucks/EachCompare.vue";
export default {
  components: {
    EachCompare,
  },
  computed: {
    compTrucks() {
      return this.$store.state.compareTrucks;
    },
    filteredTrucks() {
      return this.$store.getters.allCompareTrucks;
    },
  },
  methods: {
    printPdf() {
      window.print();
    },
  },
  created() {
    if (this.compTrucks.length) this.$store.dispatch("getTrucks");
  },
};
</script>

<style scoped></style>
